import Image from 'next/image';
import React from 'react';

const Footer = () => {
  return (
   <> 
       <footer className="bg-gray-100">
        <div className="py-6">
            <div className="container">
                <div className="grid lg:grid-cols-2 items-center">
                    <div>
                        <h1 className="text-2xl font-medium mt-3 mb-2">Ready to get started?</h1>
                        <p className="text-slate-400">Create your free account now</p>
                    </div>
                     <div className="flex flex-wrap items-center justify-start lg:justify-end gap-7 mt-5 lg:mt-0">
                         <button className="flex items-center">
                            <a href="https://app.buzzi.ai/auth#signup" className="bg-primary text-white rounded-full hover:shadow-lg hover:shadow-primary/30 focus:shadow-none focus:outline focus:outline-primary/40 transition-all duration-500 py-2 px-4">
                            Register Now
                            </a>
                        </button>  
                      {/*   <a href="https://www.producthunt.com/posts/buzzi-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-buzzi&#0045;ai" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=476330&theme=light" alt="Buzzi.ai" style={{ width  : "250px", height: "54px"}} width="250" height="54" /></a>  */} 
                    </div>    
                </div>
            </div>
        </div>

        <div className="border-b"></div>

        <div className="container">

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-14 py-6">
                <div>
                    <a href="/">
                    <Image className="h-8 w-auto" src="/images/logo.svg" width="249" height="67" />  
                    </a>
                    <p className="text-gray-500/80 mt-5 w-4/5">
                    Our mission is to empower businesses with cutting-edge AI technology that simplifies and enhances social media content creation. We strive to help brands maintain a cohesive and impactful online presence by providing innovative tools for generating high-quality, engaging, and branded posts that resonate with their audience.
                    </p>
                </div>
                

                <div>
                    <div className="footer-links flex flex-col sm:flex-row gap-14 flex-wrap text-md lg:ml-[20%] sm:flex-nowrap justify-between">

                        

                        <div>
                            <div className="flex flex-col gap-2">
                                <h5 className="mb-2 uppercase">Platform</h5>
                                <div className="text-gray-500/80"><a href="/">Home</a></div>
                                  <div className="text-gray-500/80"><a href="https://app.buzzi.ai/auth#signup">Register</a></div>
                                <div className="text-gray-500/80"><a href="https://app.buzzi.ai">Login</a></div>  
                                <div className="text-gray-500/80"><a href="/contact">Contact Us</a></div>
                            </div>
                        </div>

                        <div>
                            <div className="flex flex-col gap-2">
                                <h5 className="mb-2 uppercase">Legal</h5>
                                <div className="text-gray-500/80"><a href="/privacy"  target='_blank'>Privacy Policy</a></div>
                                <div className="text-gray-500/80"><a href="/terms" target='_blank'>Terms of Service</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-b"></div>

            <div className="grid sm:grid-cols-2 text-center sm:text-start gap-6 py-5">
                <div>
                    <p className=" text-sm text-slate-500">
                        {new Date().getFullYear()}© Buzzi AI. All rights reserved. 
                    </p>
                </div>

                <div className="flex justify-center sm:justify-end gap-7">
                    
                    <div>
                        <a href="https://www.facebook.com/buzziai">
                            <span 
                                style={{ maskImage: `url("/images/icons/facebook.svg")`}}
                                className="inline-block w-5 h-5 icon-mask bg-slate-500/90  hover:bg-primary transition-all" >
                            </span>
                        </a>
                    </div>
                    <div>
                        <a href="https://x.com/buzzi_ai">
                            <span 
                                style={{ maskImage: `url("/images/icons/x.svg")`}}
                                className="inline-block w-5 h-5 icon-mask bg-slate-500/90  hover:bg-primary transition-all" >
                            </span>
                        </a>
                    </div>

                    <div>
                        <a href="https://linkedin.com/company/buzzi-ai">
                            <span 
                                style={{ maskImage: `url("/images/icons/linkedin.svg")`}}
                                className="inline-block w-5 h-5 icon-mask bg-slate-500/90  hover:bg-primary transition-all" >
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
   </>
  );
};

export default Footer;


 