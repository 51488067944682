import React, { useState } from 'react';
import Link from 'next/link';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header id="navbar" className="fixed top-0 inset-x-0 flex items-center z-40 w-full lg:bg-transparent bg-white transition-all py-5">
      <div className="container">
        <nav className="flex items-center justify-between">
          <Link href="/">
            <img src="/images/logo.svg" className="h-6 logo-dark" alt="Logo" />
          </Link>

          <div className="hidden md:block">
            <ul className="navbar-nav flex gap-x-3 items-center justify-center">
              <li className="nav-item">
                <Link href="/#features" className="nav-link">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/#pricing" className="nav-link">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/#faq" className="nav-link">
                  Faq
                </Link>
              </li>
              <li className="nav-item">
                <Link href="/contact/" className="nav-link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="act flex items-center">
              <a href="https://app.buzzi.ai" target="_blank" className="text-primary-600 border border-primary-600 hover:bg-primary-600 hover:text-white px-4 py-2 rounded inline-flex items-center text-md">
              Go to App
            </a>  
          </div>

          <button
            className="md:hidden text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
            onClick={toggleMobileMenu}
          >
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </nav>
      </div>

      {/* Mobile menu */}
      <div
        className={`fixed inset-0 z-50 bg-white ${
          isMobileMenuOpen ? 'block' : 'hidden'
        } md:hidden`}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-4">
            <Link href="/">
              <img src="/images/logo.svg" className="h-6 logo-dark" alt="Logo" />
            </Link>
            <button
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              onClick={toggleMobileMenu}
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              </svg>
            </button>
          </div>
          <div className="flex-grow overflow-y-auto">
            <ul className="px-4 py-2">
              <li className="py-2">
                <Link href="#features" className="block text-gray-800 hover:text-gray-600" onClick={toggleMobileMenu}>
                  Features
                </Link>
              </li>
              <li className="py-2">
                <Link href="#pricing" className="block text-gray-800 hover:text-gray-600" onClick={toggleMobileMenu}>
                  Pricing
                </Link>
              </li>
              <li className="py-2">
                <Link href="#faq" className="block text-gray-800 hover:text-gray-600" onClick={toggleMobileMenu}>
                  Faq
                </Link>
              </li>
            </ul>
          </div>
          <div className="p-4">
            <a
              href="https://app.buzzi.ai"
              target="_blank"
              className="block w-full text-center text-primary-600 border border-primary-600 hover:bg-primary-600 hover:text-white px-4 py-2 rounded text-md"
            >
              Go to App
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;